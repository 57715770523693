import { render, staticRenderFns } from "./header.vue?vue&type=template&id=7b2b5a0f&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderBurger: require('/home/d/didisher2/ac-algaauto.ru/www/components/Header/header-burger.vue').default,HeaderLogo: require('/home/d/didisher2/ac-algaauto.ru/www/components/Header/header-logo.vue').default,SiteListDesktop: require('/home/d/didisher2/ac-algaauto.ru/www/components/site-list/site-list-desktop.vue').default,ModalMenu: require('/home/d/didisher2/ac-algaauto.ru/www/components/modal/modal-menu.vue').default,Header: require('/home/d/didisher2/ac-algaauto.ru/www/components/Header/header.vue').default})
